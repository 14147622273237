import React, { DOMAttributes } from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import IntercoIcons from 'src/components/IntercoIcons'
import DinamicIcon from './DinamicIcon'
import { IconsSwitchProps } from './types'

interface IIconsSwitchProps extends IconsSwitchProps, DOMAttributes<HTMLElement> { }

const IconsSwitch = ({ icon, size, width, height, color, lib, className, customPath = '', ...rest }: IIconsSwitchProps) => {
  switch (lib) {
    case 'oldInterco':
      return (
        <IntercoIcons
          {...rest}
          icon={`${icon}`}
          size={size}
          color={color}
          className={className}
        />)

    case 'frontend-lib':
      return (
        <OrangeIcon
          {...rest}
          icon={`${icon}`}
          size={size}
          color={color}
          className={className}
        />)

    case 'customInterco':
      return (
        <DinamicIcon
          {...rest}
          icon={`${icon}`}
          size={size}
          color={color}
          className={className}
          customPath={`build-v4/${customPath}`}
        />)

    case 'interco':
      return (
        <DinamicIcon
          {...rest}
          icon={`${icon}`}
          width={width}
          height={height}
          color={color}
          className={className}
          customPath={`core/${customPath}`}
        />)

    default:
      return (
        <OrangeIcon
          {...rest}
          icon={`${icon}`}
          size={size}
          color={color}
          className={className}
        />)
  }
}

export default IconsSwitch
