
import React, { useEffect, useState } from 'react'
import useWidth from 'src/hooks/window/useWidth'
import { widths } from 'src/styles/breakpoints'

type SizesT = {
  sm?: number | 'auto';
  md?: number | 'auto';
  lg?: number | 'auto';
  xl?: number | 'auto';
  xxl?: number | 'auto';
  xxxl?: number | 'auto';
}

type ImageWebpWithSizesProps = {
  pathSrc: string;
  altDescription: string;
  className?: string;
  widths?: SizesT;
  heights?: SizesT;
  style?: {};
  title?: string;
}

export const ImageWebpWithSizes = ({
  widths,
  heights,
  pathSrc,
  altDescription,
  className,
  style,
  title,
}: ImageWebpWithSizesProps) => {
  const indexSizeMap = {
    0: 'sm',
    1: 'md',
    2: 'lg',
    3: 'xl',
    4: 'xxl',
    5: 'xxxl',
  }
  const createMappedArray = (sizesObject: SizesT) => {
    const emptyArray = [ 0, 0, 0, 0, 0, 0 ]
    return emptyArray.map((_: number, index: number) => {
      const sizeKey = Object.values(indexSizeMap)[index] as keyof SizesT
      return sizesObject[sizeKey] ?? 0
    })
  }

  const widthArray = widths ? createMappedArray(widths) : undefined

  const heightArray = heights ? createMappedArray(heights) : undefined

  return <ImageWebp title={title} style={style} arrayNumbers={widthArray} arrayNumbersHeight={heightArray} className={className} pathSrc={pathSrc} altDescription={altDescription} />
}

type ImageWebpProps = {
  arrayNumbers?: (number| 'auto')[];
  arrayNumbersHeight?: (number| 'auto')[];
  pathSrc: string;
  altDescription: string;
  className?: string;
  title?: string;
  style?: {};
}

const ImageWebp = ({ arrayNumbers, arrayNumbersHeight, pathSrc, altDescription, className, style, title }: ImageWebpProps) => {
  const width = useWidth()
  const [ isWidth, setIsWidth ] = useState<number | 'auto'>(0)
  const [ isHeight, setIsHeight ] = useState<number | 'auto'>(0)

  useEffect(() => {
    if (width < widths.md) {
      arrayNumbers && setIsWidth(arrayNumbers[0])
      arrayNumbersHeight && setIsHeight(arrayNumbersHeight[0])
    } else if (width >= widths.md && width < widths.lg) {
      arrayNumbers && setIsWidth(arrayNumbers[1])
      arrayNumbersHeight && setIsHeight(arrayNumbersHeight[1])
    } else if (width >= widths.lg && width < widths.xl) {
      arrayNumbers && setIsWidth(arrayNumbers[2])
      arrayNumbersHeight && setIsHeight(arrayNumbersHeight[2])
    } else if (width >= widths.xxl && width < widths.xxxl) {
      arrayNumbers && setIsWidth(arrayNumbers[3])
      arrayNumbersHeight && setIsHeight(arrayNumbersHeight[3])
    } else if (width >= widths.xxxl) {
      arrayNumbers && setIsWidth(arrayNumbers[4] ? arrayNumbers[4] : arrayNumbers[3])
      arrayNumbersHeight && setIsHeight(arrayNumbersHeight[4])
    }
  }, [ width, widths, isHeight ])
  return (
    <img
      src={pathSrc}
      alt={altDescription}
      title={title}
      className={className}
      width={isWidth || '100%' }
      height={isHeight || '100%'}
      style={{ ...style }}
    />
  )
}

export default ImageWebp
