import { breakpoints } from "src/styles/breakpoints"
import { orange, white } from "src/styles/colors"
import styled from "styled-components"

export const Section = styled.section`
  background-color: #72370E;

  h1 {
    font-family: 'Citrina', Helvetica, sans-serif;
  }

  @media (min-width: ${breakpoints.md}){
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/inss-hero-768/image.png') no-repeat ;
    min-height: 415px;
    background-color: #72370E;
  }

  @media (min-width: ${breakpoints.lg}){
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/inss-hero-1024/image.png') no-repeat ;
    min-height: 537px;
    background-color: #72370E;
  }

  @media (min-width: ${breakpoints.xl}){
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/inss-hero-1440/image.png') no-repeat ;
    min-height: 568px;
    background-color: #72370E;
  }
`

export const Button = styled.button`
  background-color: ${orange.extra};
  border: none;
  border-radius: 8px;
  width: 100%;
  height: 48px;
  color: ${white};
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  margin-top: 32px;

  &:focus, &:hover {
    outline: none;
    color: ${white};
  }

  @media (min-width: ${breakpoints.md}) {
    width:  331px;
    margin-top: 40px;
  }

  @media (min-width: ${breakpoints.lg}) {
    width:  376px;
  }

  @media (min-width: ${breakpoints.xl}) {
    width:  456px;
  }

`
